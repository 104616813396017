import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"

const ContactPage = () => {
    return (
        <Layout>
            <SEO title="Contact" description="Contact me with questions about my services or if you're interested in having a website built." />
            <main className="d-flex flex-column align-items-center">
                <title>Contact</title>
                <form id="contactForm" name="contact" className="contact" method="POST" encType="application/x-www-form-urlencoded" data-netlify="true">
                    <div className="form-group mb-3">
                        <label htmlFor="nameInput">Name</label>
                        <input type="text" className="form-control" id="nameInput" placeholder="Name" name="Name" required></input>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="emailInput">Email</label>
                        <input type="email" className="form-control" id="emailInput" placeholder="Email Address" name="Email" required></input>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="businessInput">Business</label>
                        <input type="text" className="form-control" id="businessInput" placeholder="Business Name" name="Business"></input>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="commentsInput">Comments</label>
                        <textarea className="form-control" id="commentsInput" placeholder="What are you looking for?" name="Comments" required></textarea>
                    </div>
                    <input type="hidden" name="form-name" value="contact"></input>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </main>
        </Layout>
  )
}
  
export default ContactPage